import { Edit1 } from "@repo/icons";
import { CpsBadge, type CpsBadgeProps } from "corpus";

interface PaymentStatusBadgeProps {
  status?: string;
  onClickStatusBadge: CpsBadgeProps["onClick"];
}

export const PaymentStatusBadge = ({
  status,
  onClickStatusBadge,
}: PaymentStatusBadgeProps): JSX.Element => {
  const badgeDictionary: Record<
    string,
    {
      text: string;
      color: CpsBadgeProps["color"];
    }
  > = {
    paid: { text: "Pago", color: "success" },
    pending: { text: "Em Aberto", color: "secondary" },
    refunded: { text: "Estornado", color: "danger" },
    canceled: { text: "Cancelado", color: "neutral" },
    failed: { text: "Falha", color: "danger" },
  };

  const { text, color } = badgeDictionary[status ?? "fail"];

  return (
    <CpsBadge
      data-align="end"
      color={color}
      shade="dark"
      variant="square"
      className="uppercase"
      onClick={onClickStatusBadge}
      gap
    >
      {text}
      {onClickStatusBadge ? <Edit1 size={16} className="fill-white" /> : undefined}
    </CpsBadge>
  );
};
