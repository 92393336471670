import { type SVGIconProps } from "@repo/icons";
import { cn } from "@repo/lib";
import { CpsBadge } from "corpus";
import { type FunctionComponent } from "react";

interface InfoSectionV2Fields {
  label: string;
  labelTag?: string;
  value?: string | null;
  valueIsRegular?: boolean;
}

interface InfoSectionV2Subsection {
  title?: string;
  fields: InfoSectionV2Fields[];
}

export interface InfoSectionV2Props {
  section: {
    Icon?: FunctionComponent<SVGIconProps>;
    title: string;
    fields: InfoSectionV2Fields[];
    subsections?: InfoSectionV2Subsection[];
  };
  showBorderForLastField?: boolean;
}

const InfoFields = ({
  fields,
  showBorderForLastField,
}: {
  fields: InfoSectionV2Fields[];
  showBorderForLastField?: boolean;
}): JSX.Element => {
  return (
    <>
      {fields.map((field, index) => {
        if (!field.value) return null;

        const isLastField = index !== fields.length - 1;
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing --- com ?? para de exibir as divisorias
        const shouldIncludeBorderInLastField = showBorderForLastField || isLastField;

        return (
          <div
            key={field.label}
            className={cn(
              "flex justify-between",
              shouldIncludeBorderInLastField
                ? "border-b-neutral-300 border-b border-spacing-30"
                : "",
            )}
          >
            <p className="flex gap-1 text-neutral-500">
              {field.label}
              {field.labelTag ? (
                <CpsBadge color="helper">{field.labelTag}</CpsBadge>
              ) : null}
            </p>
            <p
              className={cn(
                "text-neutral-500 mb-3",
                field.valueIsRegular ? "font-normal" : "font-semibold",
              )}
            >
              {field.value}
            </p>
          </div>
        );
      })}
    </>
  );
};

export const InfoSectionV2 = ({
  section,
  showBorderForLastField = true,
}: InfoSectionV2Props): JSX.Element | null => {
  const { Icon, title, fields, subsections } = section;

  const hasFields = fields.some((field) => field.value);
  const hasSubsections = subsections && subsections.length > 0;

  if (!hasFields && !hasSubsections) return null;

  return (
    <div className="flex flex-col" key={title}>
      <div>
        {Icon ? (
          <div className="bg-neutral-50 rounded-full p-1 w-[30px] h-[30px] flex items-center justify-center">
            <Icon size={16} />
          </div>
        ) : null}
        <h3 className="font-semibold text-neutral-600 mb-1">{title}</h3>
      </div>

      {hasFields ? (
        <div className="flex flex-col gap-3">
          <InfoFields fields={fields} showBorderForLastField={showBorderForLastField} />
        </div>
      ) : null}

      {hasSubsections
        ? subsections.map((subsection, sIdx) => {
            const hasSubsectionFields = subsection.fields.some((f) => f.value);
            if (!hasSubsectionFields) return null;

            return (
              <div key={subsection.title ?? sIdx} className="flex flex-col gap-3 mb-12">
                {subsection.title ? (
                  <h3 className="font-semibold text-neutral-600 mb-1">
                    {subsection.title}
                  </h3>
                ) : null}
                <InfoFields
                  fields={subsection.fields}
                  showBorderForLastField={showBorderForLastField}
                />
              </div>
            );
          })
        : null}
    </div>
  );
};
