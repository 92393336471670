import { formatWithZonedDate } from "@repo/lib";
import { Money } from "@repo/icons";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { InfoSectionV2 } from "@/components/info-section-v2";

export const PatientPaymentsDetailsPaymentSectionFragment = graphql(`
  fragment PatientPaymentsDetailsPaymentSectionFragment on ExpensumPaymentDetailsOutput {
    acquirerTransactionKey
    createdAt
    installments
    origin
    paidAt
    paymentMethod
    status
    refundedAt
    note
    onlinePaymentInfo {
      type
    }
  }
`);

interface PatientPaymentsDetailsPaymentSectionProps {
  data: FragmentType<typeof PatientPaymentsDetailsPaymentSectionFragment>;
}

export const PatientPaymentsDetailsPaymentSection = ({
  data,
}: PatientPaymentsDetailsPaymentSectionProps): JSX.Element => {
  const payment = useFragment(PatientPaymentsDetailsPaymentSectionFragment, data);

  const { onlinePaymentInfo } = payment;

  const paymentNote = payment.note ? payment.note : "";

  const transactionId = payment.acquirerTransactionKey ?? "";

  const originPaymentDictionary: Record<string, string> = {
    Online: "Link de pagamento",
  };

  const getOriginPaymentText = (key: string): string => {
    return originPaymentDictionary[key] ?? key;
  };

  const typePaymentOnlineDictionary: Record<string, string> = {
    pre: "Pré atendimento",
    pos: "Pós atendimento",
    avulso: "Pagamento Avulso",
  };

  const getTypePaymentOnlineText = (key: string): string => {
    return typePaymentOnlineDictionary[key] ?? key;
  };

  const buildPaymentMethodInformation = (): string => {
    const installments = payment.installments;
    const paymentType = payment.paymentMethod;

    const buildCreditLabel = (): string => {
      if (!installments) return "Crédito";

      return `Crédito em ${installments}x`;
    };

    const paymentMethodsDictionary: Record<string, string> = {
      credito: buildCreditLabel(),
      debito: "Débito",
      pix: "PIX",
    };

    return paymentType ? paymentMethodsDictionary[paymentType] : "";
  };

  const paymentSection = {
    Icon: Money,
    title: "Detalhes do pagamento",
    fields: [
      {
        label: "Criado em",
        value: formatWithZonedDate(payment.createdAt, "dd/MM/yyyy"),
      },
      {
        label: "Estornado em",
        value:
          payment.refundedAt 
            ? formatWithZonedDate(payment.refundedAt, "dd/MM/yyyy • HH:mm")
            : undefined,
      },
      {
        label: "Pago em",
        value:
          payment.status === "paid" && payment.paidAt
            ? formatWithZonedDate(payment.paidAt, "dd/MM/yyyy • HH:mm")
            : undefined,
      },
      { label: "Forma de pagamento", value: buildPaymentMethodInformation() },
      {
        label: "Origem",
        value: getOriginPaymentText(payment.origin),
      },
      {
        label: "Tipo",
        value: getTypePaymentOnlineText(onlinePaymentInfo?.type ?? ""),
      },
      {
        label: "ID da transação",
        value: transactionId,
      },
    ],
  };

  if (payment.status === "canceled" && payment.note) {
    paymentSection.fields.push({
      label: "Observação",
      value: "Cancelamento",
    });
  }

  return (
    <>
      <InfoSectionV2 section={paymentSection} showBorderForLastField={!payment.note} />
      {paymentNote ? (
        <p className="text-sm text-medium-500 mt-[-30px]">{paymentNote}</p>
      ) : null}
    </>
  );
};
